<template>
  <div class="pad-3">
    <div class="bb-container bb-container--lista">
      <h1 class="header-1">{{ $t("legalNotice.legalNotice") }}</h1>
      <div class="post-entry">
        <h3>{{ $t("legalNotice.art1") }}</h3>
        <p>
          {{ $t("legalNotice.art1Text") }}
        </p>

        <h3>{{ $t("legalNotice.art2") }}</h3>
        <p>
          {{ $t("legalNotice.art2Text1") }}
        </p>
        <p>
          {{ $t("legalNotice.art2Text2") }}
        </p>
        <p>
          {{ $t("legalNotice.art2Text3") }}
        </p>
        <p>
          {{ $t("legalNotice.art2Text4") }}
        </p>
        <p>
          {{ $t("legalNotice.art2Text5") }}
        </p>

        <p>
          {{ $t("legalNotice.art2Text6") }}
        </p>

        <h3>{{ $t("legalNotice.art3") }}</h3>

        <p>
          {{ $t("legalNotice.art3Text1") }}
          <br />
        </p>
        <p>
          {{ $t("legalNotice.art3Text2") }}
        </p>

        <p>
          {{ $t("legalNotice.art3Text3") }}
        </p>
        <h3>{{ $t("legalNotice.art4") }}</h3>
        <p>
          {{ $t("legalNotice.art4Text1") }}
        </p>
        <p>{{ $t("legalNotice.art4Text2") }}</p>
        <p>
          {{ $t("legalNotice.art4Text3") }}
        </p>
        <p>
          {{ $t("legalNotice.art4Text4") }}
        </p>
        <p>
          {{ $t("legalNotice.art4Text5") }}
        </p>
        <h3>{{ $t("legalNotice.art5") }}</h3>
        <p>
          {{ $t("legalNotice.art5Text1") }}
        </p>
        <p>
          {{ $t("legalNotice.art5Text2") }}
        </p>
        <p>
          {{ $t("legalNotice.art5Text3") }}
        </p>
        <p>
          {{ $t("legalNotice.art5Text4") }}
        </p>
        <h3>{{ $t("legalNotice.art6") }}</h3>
        <p>
          {{ $t("legalNotice.art6Text1") }}
        </p>
        <p>
          {{ $t("legalNotice.art6Text2") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Legal",
  metaInfo() {
    return {
      title: this.$t("legalNotice.metaTitle"),
      meta: [
        { name: "description", content: this.$t("legalNotice.metaDescription") },
        { name: "robots", content: "noindex"}
      ],
    };
  },
};
</script>
